import PortalIdParser from 'PortalIdParser';
import { parse } from 'hub-http/helpers/params';
import getCookie from '../utils/getCookie';
import { api } from '../utils/urls';
const addApiCsrf = function addApiCsrf(request) {
  request.setRequestHeader('X-HubSpot-CSRF-hubspotapi', getCookie('csrf.app') || '');
};
const setBaseRequestProps = function setBaseRequestProps(request) {
  request.withCredentials = true;
  request.setRequestHeader('Accept', 'application/json');
  request.setRequestHeader('Content-type', 'application/json');
  addApiCsrf(request);
};
const tryParseAsJSON = function tryParseAsJSON(resp) {
  try {
    return JSON.parse(resp);
  } catch (e) {
    return resp;
  }
};
const GET = function GET(path, cb, cbFail, params) {
  const request = new XMLHttpRequest();
  request.open('GET', path);
  setBaseRequestProps(request);
  request.onload = function () {
    if (this.status >= 200 && this.status < 400) {
      if (cb) {
        const parsedResponse = tryParseAsJSON(this.response);
        if (params && 'openReview' in params) {
          const separator = new URL(parsedResponse.uri).search ? '&' : '?';
          parsedResponse.uri = `${parsedResponse.uri}${separator}openReview=${params.openReview}`;
        }
        cb(parsedResponse);
      }
    } else {
      cbFail(this);
    }
  };
  request.onerror = function () {
    cbFail();
  };
  request.send();
};
export default class HomeDAO {
  static get(portalIdFromEndpoint = null, cb, cbf) {
    const portalId = portalIdFromEndpoint || PortalIdParser.getPortalIdFromQueryParam() || getCookie('hubspot.hub.id');
    const apiPath = portalId ? '/home/v2/api' : '/home/v2/api/no-intended-portal';
    const params = portalId ? {
      portalId
    } : {};
    if (window.location && window.location.search) {
      const searchParams = parse(window.location.search.replace(/^\?/, ''));
      Object.keys(searchParams).forEach(key => {
        params[key] = searchParams[key];
      });
    }
    GET(`${api(apiPath)}${portalId ? `?portalId=${portalId}` : ''}`, cb, cbf, params);
  }
  static getUserPortalId(cb, cbf) {
    const apiPath = '/home/v2/api/portal';
    GET(api(apiPath), cb, cbf);
  }
}